@font-face {
  font-family: "emfatick";
  src: url("../../public/fonts/Emfatick NF Regular.otf") format("truetype");
}

.aboutme {
  margin: 5vh;
  padding-bottom:20px;
  overflow:hidden;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.aboutme h1 {
  font-size: 7vw;
  /* text-align: left; */
  margin-bottom: 0px;
  color: #e0e0da;
  font-family: "emfatick";
  font-weight: 50;
}


.aboutmesection {
  column-count: 2;
  /* margin: 100px;
  margin-bottom:200px; */
  display:flex;
  justify-content:center;
  padding: 5vw;
  background-color: #2d2727;
  overflow:hidden;
}

.aboutme p {
    font-size: 1.4vw;
    /* position: absolute;
    transform: translate(40%, 60%); */
    /* margin: auto; */
    color: #cdcdcd;
    width: 40vw;
    font-family:monospace;
    font-weight: 50px;
    line-height:120%
  }
  
.aboutmesection img {
  /* position: right; */
  width:25vw;
  height:34vw;
  margin-top:10vw;
  margin-left:3vw;
  border-radius: 50px;
  animation: fadeIn 1s;
}


.music{
  background-color:#ffffff;
  margin-top:-3.5vw;
  overflow:hidden;
}
.music h1 {
  font-size:2.6vw;
  /* position: absolute;
  transform: translate(30%, 500%); */
  margin-left: 7vw;
  padding-top:7vw;
  color: #5e5555;
  width: 70vw;
  font-family:monospace;
  font-weight: 650;
}


.musicvinyls{
    column-count:3;
    padding-bottom: 70px;
    margin-left:30px

}

@keyframes spin {
  from {
    transform: rotate(0deg); /* Start from 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate fully (360 degrees) */
  }
}

.music .breezeblocksVinyl {
  /* position: absolute;
  transform: translate(49%, -160%); */
  width: 23vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin:50px;
  animation: spin 3s linear infinite ; /* Apply spin animation */
}


.music .everseenVinyl {
  /* position: absolute;
  transform: translate(190%, -160%); */
  width: 23vw;
  border-radius: 50 px;
  cursor: pointer;
  overflow-x: hidden;
  margin:50px;
  animation: spin 3s linear infinite; /* Apply spin animation */
}


.music .ivyVinyl {
  /* position: absolute;
  transform: translate(340%, -160%); */
  width: 23vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin:50px;
  animation: spin 3s linear infinite; /* Apply spin animation */
}


.music .ivyVinyl:hover {
  animation-play-state: paused;
  transform: scale(1.1);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.music .breezeblocksVinyl:hover {
  animation-play-state: paused;
    transform: scale(1.1);
    transition: transform 0.3s ease;
    transform-origin: center;
  }

  .music .everseenVinyl:hover{
    animation-play-state: paused;
    transform: scale(1.1);
    transition: transform 0.3s ease;
    transform-origin: center;
  }
  

/* .music .musicbackground {
  width: 100vw;
} */

.breezeblocks h2{
    font-size: 1.8vw;
    color: #5e5555;
  width: 500px;
  font-family: monospace;
  font-weight: 650;
  padding-left:3vw;
}
.everseen h2{
  font-size: 1.8vw;
  color: #5e5555;
width: 500px;
font-family: monospace;
font-weight: 650;
padding-left:2.5vw;
}

.ivy h2{
  font-size: 1.8vw;
  color: #5e5555;
width: 500px;
font-family: monospace;
font-weight: 650;
padding-left:3vw;
}

@media (max-width: 700px) {
  .aboutmesection img {
    /* position: right; */
    width:25vw;
    height:34vw;
    margin-top:18vw;
    margin-left:3vw;
    border-radius: 16px;
    animation: fadeIn 1s;
  }

  .music h1 {
    font-size: 4vw;
    /* position: absolute;
    transform: translate(30%, 500%); */
    margin-left: 20vw;
    padding-top:10vw;
    color: #5e5555;
    width: 60vw;
    font-family:monospace;
    font-weight: 650;
  }
  .musicvinyls{
    column-count:1;
    padding-bottom:10vw;
    margin-left:25vw;

}
.music .breezeblocksVinyl {
  /* position: absolute;
  transform: translate(49%, -160%); */
  width: 40vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin:5vw;
  animation: spin 3s linear infinite ; /* Apply spin animation */
}


.music .everseenVinyl {
  /* position: absolute;
  transform: translate(190%, -160%); */
  width: 40vw;
  border-radius: 50 px;
  cursor: pointer;
  overflow-x: hidden;
  margin:5vw;
  animation: spin 3s linear infinite; /* Apply spin animation */
}


.music .ivyVinyl {
  /* position: absolute;
  transform: translate(340%, -160%); */
  width: 40vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin:5vw;
  animation: spin 3s linear infinite; /* Apply spin animation */
}
.breezeblocks h2{
  font-size: 2.8vw;
  color: #5e5555;
width: 40vw;
font-family: monospace;
font-weight: 650;
padding-left:8vw;
}
.everseen h2{
font-size: 2.8vw;
color: #5e5555;
width: 40vw;
font-family: monospace;
font-weight: 650;
padding-left:4vw;
}

.ivy h2{
font-size: 2.8vw;
color: #5e5555;
width: 40vw;
font-family: monospace;
font-weight: 650;
padding-left:8vw;
}
  }

