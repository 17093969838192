html,
body {
  width: 100%;
  overflow-x: hidden;
}

.subbud-header {
  margin: 0%;
  overflow: hidden;
}

.subBud-prototype-link{
columns:2;
}

.subBud-prototype-link:hover{
  opacity:50%;
  cursor:pointer;
  }


.subBud-prototype-link .link-icon img{
  width:1vw;
  position: absolute;
  top:62%;
  left:23.5%;
}


.subBud-prototype-link h1{
  font-family: Arial, Helvetica, sans-serif;
  font-size:1.1vw;
  color:#6B6B6B;
  position: absolute;
  top:60.3%;
  left:15%;
}
.subbud-header img {
  width: 100vw;
}

.subbud-section1 {
  display: flex;
  margin-left: 18vw;
  margin-top: 4vw;
  margin-bottom: 4vw;
  flex-direction: column;
  align-items: flex-start;
}

.subbud-overview {
  font-weight: 200;
  font-size: 0.6vw;
  width: 60vw;
}

.subbud-overview h1 {
  font-weight: 500;
  margin-bottom: 3vw;
  line-height: 1.8vw;
}

.subbud-intro {
  display: flex;
  justify-content: flex-start;
  font-family: Arial, Helvetica, sans-serif;
  width: 60vw;
  gap: 10vw;
}

.project-duration h1 {
  flex: 1;
  font-size: 1.3vw;
}

.subbud-skills h1 {
  flex: 1;
  font-size: 1.3vw;
}

.subbud-skills h2 {
  font-weight: 400;
  font-size: 1.2vw;
  margin-bottom: 0.5vw;
}
.project-duration h2 {
  font-weight: 400;
  font-size: 1.2vw;
  margin-bottom: 0.5vw;
}

.subbud-section2 {
  background-color: rgb(247, 247, 247);
  display: flex;
  padding-left: 18vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
  flex-direction: column;
  align-items: flex-start;
}

.subbud-section2 h1 {
  font-size: 2vw;
}

.subbud-section2 h2 {
  font-size: 1.5vw;
  font-weight: 600;
  width: 60vw;
  line-height: 2vw;
}

.designProcess1 {
  padding-left: 18vw;
  padding-top: 3vw;
}

.designProcess1 img {
  width: 60vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
}

.designProcess1 h1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 2vw;
}

.designProcess1 h2 {
  margin-bottom: 2vw;
  width: 60vw;
  font-size: 1.2vw;
  font-weight: 1.1vw;
  width: 60vw;
  line-height: 2vw;
  line-height: 1.8vw;
}

.designProcess1 h3 {
  margin-bottom: 1vw;
  width: 60vw;
  font-size: 1.2vw;
  font-weight: 600;
  width: 60vw;
}

.userInterviews {
  margin-top: 8vw;
  padding-left: 18vw;
  padding-right: 18vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(247, 247, 247);
}

.userInterviews h1 {
  font-size: 2vw;
  padding-top: 4vw;
}
.userInterviews h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 69vw;
}

.userInterviews h3 {
  margin-bottom: 1vw;
  font-size: 1.1vw;
  font-weight: 500;
  width: 54vw;
}

.keyFindings {
  display: flex;
  padding-top: 4vw;
  padding-bottom: 1vw;
  flex-direction: column;
  align-items: flex-start;
}
.keyFindings h3 {
  width: 20vw;
}

.keyFindings-row1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2vw; 
}

.keyFinding1,
.keyFinding2,
.keyFinding3 {
  width: 30%; 
  margin-bottom: 2vw;
}

.keyFindings-row2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2vw; 
}

.keyFinding4,
.keyFinding5,
.keyFinding6 {
  width: 30%; 
  margin-bottom: 2vw;
}

.secondaryResearch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 18vw !important;
  padding-top: 4vw !important;
  padding-right: 4vw !important; 
  padding-bottom: 4vw !important;
}


.secondaryResearch h1 {
  font-size: 2vw;
}

.secondaryResearch h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 65vw;
}

.secondaryResearch h3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 65vw;
  font-weight: 600;
}

.competitor-reviews img {
  width: 60vw;
  padding-top: 2vw;
}

.empathyMap {
  margin-top: 4vw;
}
.empathyMap img {
  width: 55vw;
  padding-top: 4vw;
}

.userPersona {
  padding-top: 4vw;
}
.userPersona img {
  width: 60vw;
  padding-top: 2vw;
}

.ideation {
  display: flex;
  padding-top: 4vw;
  padding-left:18vw;
  padding-right: 4vw;
  padding-bottom: 4vw;
  flex-direction: column;
  align-items: flex-start;
}

.ideation h1 {
  font-size: 2vw;
}

.ideation h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 65vw;
}

.ideation img {
  width: 60vw;
  padding-top: 2vw;
  padding-bottom: 4vw;
}

.prototype {
  display: flex;
  padding-left: 18vw;
  padding-top: 4vw;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(247, 247, 247);
}

.prototype img {
  width: 60vw;
  padding-top: 5vw;
  padding-bottom: 4vw;
}

.prototype h1 {
  font-size: 2vw;
}

.prototype h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 65vw;
}

.iterations{
  display: flex;
  padding-top: 4vw;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(247, 247, 247);
}
.iterations h1{
  font-size: 2vw;
  padding-left: 18vw;
}

.iterations h2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 65vw; 
  padding-left: 18vw;
}
.iteration1 img {
  width: 68vw;
  padding-bottom: 4vw;
  padding-left: 15vw;
  padding-top:4vw;
}
.iteration2 img {
  width: 64vw;
  padding-top: 5vw;
  padding-left: 17vw;
  padding-bottom: 2vw;
}
.iteration3 img {
  width: 40vw;
  padding-top: 1vw;
  padding-left: 30vw;
  padding-bottom: 0vw;
}

.finalDesign {
  display: flex;
  padding-left: 18vw;
  padding-top: 4vw;
  flex-direction: column;
  align-items: flex-start;
}

.finalDesign h1{
  font-size: 2vw;
  /* padding-left: 18vw; */
}

.final-design-img img {
  width: 60vw;
  padding-bottom: 4vw;
  padding-top: 4vw;
}

.homepage-text h1 {
  font-size: 2vw;
}

.homepage-text h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 21vw;
}
.homepage {
  columns: 2;
  gap: 5vw;
}

.homepage-text {
  padding-top: 17vw;
}
.homepage img {
  width: 42vw;
  padding-bottom: 4vw;
  padding-top: 4vw;
}

.manageSub {
  display: flex;
  padding-left: 5vw;
  padding-top: 4vw;
  flex-direction: column;
  align-items: flex-start;
}
.manageSub img {
  width: 90vw;
  padding-bottom: 4vw;
  padding-top: 1vw;
}

.manageSub-text h1 {
  font-size: 2vw;
}

.notifications {
  columns: 2;
  gap: 5vw;
}

.notifications img {
  width: 24vw;
  padding-top: 1vw;
  padding-left: 25vw;
}

.notification-text {
  padding-top: 15vw;
}

.notification-text h1 {
  font-size: 2vw;
}

.notification-text h2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 21vw;
}

.addingSub {
  display: flex;
  padding-left: 5vw;
  padding-top: 4vw;
  flex-direction: column;
  align-items: flex-start;
}
.addingSub-text h1 {
  font-size: 2vw;
}

.addingSub img {
  width: 90vw;
  padding-bottom: 4vw;
  padding-top: 1vw;
}

.subBud-analytics {
  columns: 2;
  gap: -4vw;
  padding-right:12vw;
}

.analytics-text{
  padding-top: 14vw;
  padding-left: 17vw;
}
.analytics-text h1{
  font-size: 2vw;
  width:20vw;
}

.analytics-text h2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 21vw;
}

.subBud-analytics img{
  width: 45vw;
  padding-bottom: 4vw;
  padding-top: 4vw;
}

.subBud-explore{
  columns: 2;
  gap: -4vw;
  padding-right:12vw;
}

.subBud-explore img{
  width: 23vw;
  padding-bottom: 4vw;
  padding-top: 4vw;
  padding-left: 27vw;
}

.subExplore-text{
  padding-top: 17vw;
  padding-left: 10vw;
}


.subExplore-text h1{
  font-size: 2vw;
  width:25vw;
}

.subExplore-text h2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1vw;
  width: 21vw;
}

@media(max-width:768px){

.subBud-prototype-link{
columns:2;
}

.subBud-prototype-link:hover{
  opacity:50%;
  cursor:pointer;
  }


.subBud-prototype-link .link-icon img{
  width:1vw;
  position: absolute;
  top:19%;
  left:23.5%;
}


.subBud-prototype-link h1{
  font-family: Arial, Helvetica, sans-serif;
  font-size:1.1vw;
  color:#6B6B6B;
  position: absolute;
  top:18.5%;
  left:15%;
}
}