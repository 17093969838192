.construction-msg h1{
    font-size: 1.5vw;
    font-weight: 600;
    margin-bottom: 0px;
    /* position:absolute;
      transform: translate(190%, -600%); */
    color: #4d4545;
    display: flex;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.construction-msg{
    padding-left: 35vw;
    padding-top: 20vw;
}

.DivvyUp-header img{
width:100vw;
}

.divvy-section2{
    padding-left: 18vw;
    padding-top:5vw;
    background-color:rgb(243, 243, 243);
    padding-bottom:5vw;
}

.divvy-section2 img{
    width:67vw;
    padding-top:3vw;
}

.divvy-section2 .keyFindingsDivvy{
    display:flex;
    columns:3;
    gap:4vw;
}

.keyFindingsDivvy .keyFinding-1 h2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.3vw;
    width: 20vw;
    font-weight: 600; 
}


.keyFindingsDivvy .keyFinding-1 h3{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.1vw;
    width: 20vw;
    font-weight: 500;
}

.keyFindingsDivvy .keyFinding-2 h2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.3vw;
    width: 20vw;
    font-weight: 600; 
}


.keyFindingsDivvy .keyFinding-2 h3{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.1vw;
    width: 20vw;
    font-weight: 500;
}

.keyFindingsDivvy .keyFinding-3 h2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.3vw;
    width: 20vw;
    font-weight: 600; 
}


.keyFindingsDivvy .keyFinding-3 h3{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.1vw;
    width: 20vw;
    font-weight: 500;
}

.divvy-section3{
    padding-left: 18vw;
    padding-top:5vw;
    padding-bottom:5vw;
}

.divvy-section3 h1{
    font-size:1.7vw;
    font-weight: 600;
}

.divvy-section3 h2{
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.8vw; 
    width: 65vw;
}

.divvy-section3 img{
    width: 60vw;
    padding-top:2vw;
    padding-bottom:2vw;
}

.divvy-section4{
    padding-left: 18vw;
    padding-top:5vw;
    padding-bottom:5vw;
    background-color: rgb(243, 243, 243);
}

.divvy-section4 h1{
    font-size:1.7vw;
    font-weight: 600;
}

.divvy-section4 h2{
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.8vw; 
    width: 65vw;
    padding-bottom:2vw;
}

.divvy-section4 img{
    width:65vw;
    padding-bottom:4vw;
}

.divvy-section5{
    padding-left: 18vw;
    padding-top:5vw;
    padding-bottom:5vw;
}
.divvy-section5 .divvy-final{
    display:flex;
    columns:2;
    gap:3vw;
}

.divvy-section5 .divvy-final img{
    width:43vw;
    padding-top:0vw;
    padding-bottom:4vw;
}

.divvy-section5 .divvy-final h1{
    font-size:1.7vw;
    font-weight: 600; 
}

.divvy-section5 .divvy-final h2{
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.8vw; 
    width: 23vw;
    padding-bottom:2vw;
    padding-top:11vw;
}

.divvy-section5 .divvy-onboarding h1{
    font-size:1.7vw;
    font-weight: 600;
    padding-top:2vw;
}

.divvy-section5 .divvy-onboarding h2{
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.8vw; 
    width: 65vw;
    padding-bottom:0vw;
}

.divvy-section5 .divvy-onboarding img{
    width:65vw;
    padding-bottom:4vw;
}

.divvy-section6{
    padding-left: 18vw;
    padding-top:0vw;
    padding-bottom:5vw;
}

.divvy-section6 h1{
    font-size:1.7vw;
    font-weight: 600;
}

.divvy-section6 h2{
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.8vw; 
    width: 65vw;
}