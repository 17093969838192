@font-face {
  font-family: "emfatick";
  src: url("../../public/fonts/Emfatick NF Regular.otf") format("truetype");
}

.home {
  margin: 0%;
  overflow: hidden;
}

.homeBackground img {
  width: 100vw;
}

.folkloreCD img {
  position: absolute;
  top: 68px;
  left: 35%;
  /* top: 9.4vh;
left: 35vw; */
  width: 10vw;
  animation: spin 3s linear; /* Apply spin animation */
}

@keyframes spin {
  from {
    transform: rotate(0deg); /* Start from 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate fully (360 degrees) */
  }
}
.folkloreCD img:hover {
  transform: scale(1.1);
}

.home h1 {
  font-size: 9vw;
  text-align: center;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(28%, -60%);
  color: #e0e0da;
  font-family: "emfatick";
  font-weight: 50;
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home h1.visible {
  opacity: 1;
}

.typing-text {
  font-size: 1.5vw;
  text-align: center;
  position: absolute;
  margin: auto;
  top: 60%;
  left: 60.5%;
  /* transform: translate(30%, 300%); */
  color: #e0e0da;
  font-family: monospace;
  font-weight: 50;
  animation: blink-cursor 0.75s step-end infinite; /* Cursor blinking effect */
}

@keyframes blink-cursor {
  from {
    border-right-color: rgba(0, 0, 0, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

/* .home h2 {
  font-size: 1.5vw;
  text-align: center;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(30%, 300%);
  color: #e0e0da;
  font-family: monospace;
  font-weight: 50;
  animation: fadeIn 2s; */
/* animation: typing 4s steps(22, end) forwards, blink 1s infinite; */

/* @keyframes typing {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes blink {
  0%,
  100% {
    border-right: 2px solid transparent;
  }
  50% {
    border-right: 2px solid #222;
  }
} */

.intro {
  column-count: 2;
  justify-content: right; /* Center horizontally */
  align-items: center;
  margin: 0vw;
  display: flex;
  background-color: #b5d8ab;
  margin-top: -6px;
}

.pic img {
  width: 27vw;
  margin: 5em;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em;
}

.introtext {
  padding-top: 0vw;
  width: 60vw;
}

.introtext h1 {
  font-size: 3.5vw;
  margin-bottom: 0px;
  /* position:absolute;
    transform: translate(190%, -600%); */
  color: #2d2727;
  display: flex;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.introtext p {
  font-size: 1.6vw;
  /* position: absolute;
    transform: translate(179%, -270%); */
  color: #2d2727;
  width: 580px;
  font: inter;
  font-weight: 550;
  display: flex;
  font-family: monospace;
}

.intro .intro-button button {
  background-color: #7baa81;
  border-color: transparent;
  border-radius: 50px;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  margin-left: 12vw;
  margin-top: 1vw;
  font-family: monospace;
  font-size: 1.3vw;
  font-weight: 500;
  color: #ffffff;
}

.intro .intro-button button:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
  background-color: #8fbd95;
}
.matcha-spill img {
  width: 100vw;
}

.projectsSection {
  background-color: #ffffff;
  margin-top: -1vw;
  padding-bottom: 10vw;
}

.heyMilo{
  margin-left: 20vw;
  margin-bottom: 5vw;
}
.heyMilo img{
  width: 55vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin: 30px;
  /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
}

.heyMilo h1{
  font-family: monospace;
  font-size: 1.6vw;
  margin-left: 3vw;
  width: 60vw;
}

.heyMilo img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.heyMiloButtons {
  padding: 5px;
  margin-left: 2vw;
}



.DivvyUp {
  margin-left: 20vw;
  margin-bottom: 5vw;
}

.DivvyUp img {
  width: 55vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin: 30px;
  /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
}

.DivvyUp img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.DivvyUpButtons {
  padding: 5px;
  margin-left: 2vw;
}

.Subbud {
  margin-left: 20vw;
}

.Subbud img {
  width: 55vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin: 30px;
  /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
}

.Subbud img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.SubbudButtons {
  padding: 5px;
  margin-left: 2vw;
}

.moodcraftButtons {
  padding: 5px;
  margin-left: 1vw;
}

.projectsSection .heyMiloButtons button {
  background-color: #e7f4ff;
  border-color: transparent;
  border-radius: 50px;
  padding: 0.8vw;
  margin: 10px;
  font-family: monospace;
  color: #3d6491;
}


.projectsSection .DivvyUpButtons button {
  background-color: #e0e7fc;
  border-color: transparent;
  border-radius: 50px;
  padding: 0.8vw;
  margin: 10px;
  font-family: monospace;
  color: #344ea3;
}

.projectsSection .SubbudButtons button {
  background-color: #f4f0fd;
  border-color: transparent;
  border-radius: 50px;
  padding: 0.8vw;
  margin: 10px;
  font-family: monospace;
  color: #9d8fbe;
}

.projectsSection .clearSpiderButtons button {
  background-color: #eaf5fe;
  border-color: transparent;
  border-radius: 50px;
  padding: 0.8vw;
  margin: 10px;
  font-family: monospace;
  color: #809aad;
}

.projectsSection .moodcraftButtons button {
  background-color: #e1eae2;
  border-color: transparent;
  border-radius: 8px;
  padding: 8px;
  margin: 10px;
  font-family: monospace;
  color: #9db79b;
}
.DivvyUp h1 {
  font-family: monospace;
  font-size: 1.6vw;
  margin-left: 3vw;
  width: 60vw;
}

.Subbud h1 {
  font-family: monospace;
  font-size: 1.6vw;
  margin-left: 3vw;
  width: 60vw;
}

.clearSpider {
  margin-left: 20vw;
  margin-top: 6vw;
}

.clearSpider img {
  width: 55vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin: 30px;
  /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
}

.clearSpider h1 {
  font-family: monospace;
  font-size: 1.6vw;
  margin-left: 1.3vw;
  width: 60vw;
}

.clearSpider img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.moodCraft {
  margin-left: 20vw;
  margin-top: 6vw;
}

.moodCraft img {
  width: 55vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin: 30px;
  /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
}

.moodCraft h1 {
  font-family: monospace;
  font-size: 1.6vw;
  margin-left: 1.3vw;
  width: 60vw;
}

.moodCraft img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.moreProjects {
  padding-top: 60px;
  background-color: #2d2727;
  padding-bottom: 100px;
}

.moreProjectsText h1 {
  margin-left: 50px;
  font-family: monospace;
  color: #f3f2f0;
}

.myArtwork {
  margin-left: 20vw;
  margin-top: 6vw;
}

.myArtwork img {
  width: 55vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin: 30px;
  /* box-shadow: 2px 2px 8px rgba(22, 22, 22, 0.4); */
}

.myArtwork h1 {
  font-family: monospace;
  font-size: 1.6vw;
  margin-left: 3vw;
  width: 60vw;
  color: #e0e0da;
  font-weight: 180px;
}

.myArtwork img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.otherProjects {
  margin-left: 20vw;
  margin-top: 6vw;
}

.otherProjects img {
  width: 55vw;
  border-radius: 50px;
  cursor: pointer;
  overflow-x: hidden;
  margin: 30px;
  /* box-shadow: 2px 2px 8px rgba(22, 22, 22, 0.4); */
}

.otherProjects h1 {
  font-family: monospace;
  font-size: 1.6vw;
  margin-left: 3vw;
  width: 60vw;
  color: #e0e0da;
  font-weight: 180px;
}

.otherProjects img:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  transform-origin: center;
}

@media (max-width: 768px) {
  .home h1 {
    font-size: 9vw;
    text-align: center;
    position: absolute;
    margin: auto;
    top: 16%;
    left: 51%;
    color: #e0e0da;
    font-family: "emfatick";
    font-weight: 50;
    animation: fadeIn 2s;
  }
  .typing-text {
    font-size: 1.5vw;
    text-align: center;
    position: absolute;
    margin: auto;
    top: 18.6%;
    left: 60.5%;
    color: #e0e0da;
    font-family: monospace;
    font-weight: 50;
    animation: blink-cursor 0.75s step-end infinite;
  }

  .folkloreCD img {
    position: absolute;
    top: 2.3%;
    left: 35%;
    width: 10vw;
    animation: spin 3s linear;
  }

  .pic img {
    width: 30vw;
    margin: 5vw;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
  }

  .introtext {
    padding-top: 0vw;
    width: 60vw;
  }

  .introtext h1 {
    font-size: 3.5vw;
    margin-bottom: 0px;
    color: #2d2727;
    display: flex;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .introtext p {
    font-size: 1.6vw;
    color: #2d2727;
    width: 580px;
    font: inter;
    font-weight: 550;
    display: flex;
    font-family: monospace;
  }

  .projectsSection .DivvyUpButtons button {
    background-color: #cedafd;
    border-color: transparent;
    border-radius: 8px;
    padding: 2vw;
    margin: 1vw;
    font-size: 2vw;
    font-family: monospace;
    color: #344ea3;
  }

  .projectsSection .heyMiloButtons button {
    background-color: #cee7fd;
    border-color: transparent;
    border-radius: 50px;
    padding: 0.8vw;
    margin: 10px;
    font-family: monospace;
    color: #3d6491;
  }

  .projectsSection .SubbudButtons button {
    background-color: #e8e5ef;
    border-color: transparent;
    border-radius: 8px;
    padding: 2vw;
    margin: 1vw;
    font-size: 2vw;
    font-family: monospace;
    color: #9d8fbe;
  }

  .projectsSection .clearSpiderButtons button {
    background-color: #dbe9f5;
    border-color: transparent;
    border-radius: 8px;
    padding: 2vw;
    margin: 1vw;
    font-size: 2vw;
    font-family: monospace;
    color: #809aad;
  }
  .SubbudButtons {
    padding: 1vw;
    margin-left: 2vw;
  }

  .heyMiloButtons {
    padding: 1vw;
    margin-left: 2vw;
  }
  

  .DivvyUpButtons {
    padding: 1vw;
    margin-left: 2vw;
  }

  .clearSpiderButtons {
    padding: 1vw;
    margin-left: 2vw;
  }
  .clearSpider img {
    width: 55vw;
    border-radius: 7vw;
    cursor: pointer;
    overflow-x: hidden;
    margin: 14px;
    /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
  }

  .DivvyUp img {
    width: 55vw;
    border-radius: 7vw;
    cursor: pointer;
    overflow-x: hidden;
    margin: 14px;
    /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
  }

  .heyMilo img {
    width: 55vw;
    border-radius: 7vw;
    cursor: pointer;
    overflow-x: hidden;
    margin: 14px;
    /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
  }

  .Subbud img {
    width: 55vw;
    border-radius: 7vw;
    cursor: pointer;
    overflow-x: hidden;
    margin: 14px;
    /* box-shadow: 2px 2px 8px rgba(115, 115, 115, 0.4); */
  }

  .myArtwork img {
    width: 55vw;
    border-radius: 7vw;
    cursor: pointer;
    overflow-x: hidden;
    margin: 14px;
    /* box-shadow: 2px 2px 8px rgba(22, 22, 22, 0.4); */
  }

  .otherProjects img {
    width: 55vw;
    border-radius: 7vw;
    cursor: pointer;
    overflow-x: hidden;
    margin: 14px;
    /* box-shadow: 2px 2px 8px rgba(22, 22, 22, 0.4); */
  }

  .introtext p {
    font-size: 2vw;
    color: #2d2727;
    width: 50vw;
    font: inter;
    font-weight: 550;
    display: flex;
    font-family: monospace;
  }

  .DivvyUp h1 {
    font-family: monospace;
    font-size: 3vw;
    margin-left: 3vw;
    width: 60vw;
  }

  .Subbud h1 {
    font-family: monospace;
    font-size: 3vw;
    margin-left: 3vw;
    width: 60vw;
  }
  .heyMilo h1 {
    font-family: monospace;
    font-size: 3vw;
    margin-left: 3vw;
    width: 60vw;
  }

  .clearSpider h1 {
    font-family: monospace;
    font-size: 3vw;
    margin-left: 3vw;
    width: 60vw;
  }

  .myArtwork h1 {
    font-family: monospace;
    font-size: 3vw;
    margin-left: 9vw;
    width: 60vw;
    color: #e0e0da;
    font-weight: 180px;
  }
  .otherProjects h1 {
    font-family: monospace;
    font-size: 3vw;
    margin-left: 9vw;
    width: 60vw;
    color: #e0e0da;
    font-weight: 180px;
  }

  .moreProjectsText h1 {
    margin-left: 50px;
    font-family: monospace;
    color: #f3f2f0;
    font-size: 4vw;
    width: 70vw;
  }
}
