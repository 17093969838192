.uw-ux {
  padding: 10vw;
  background-color: #2d2727;
}
.uwux-intro {
  columns: 2;
  gap: 0vw;
}
.uwux-introText {
  padding-top: 2.5vw;
}
.uwux-introText h1 {
  font-family: monospace;
  flex: 1; /* Ensure both columns take up equal space */
  font-size: 3vw;
  padding-top: 3vw;
  color: #f3f2f0;
}

.uwux-introText h2 {
  font-family: monospace;
  font-size: 1.4vw;
  font-weight: 510;
  width: 36vw;
  color: #f3f2f0;
  line-height: 3vw;
}

.uwux-intro img {
  width: 40vw;
  border-radius: 30px;
  margin-top: 7vw;
  margin-left: 0.5vw;
}

.uwux-posts img {
  width: 80vw;
  padding-top: 7vw;
}

.clear-Spider {
  padding: 10vw;

  gap: 0vw;
}

.cs-intro {
  columns: 2;
}

.clear-Spider .cs-intro img {
  width: 40vw;
}

.clearSpider-Text h1 {
  font-family: monospace;
  flex: 1; /* Ensure both columns take up equal space */
  font-size: 3vw;
  padding-top: 3vw;
  width: 25vw;
}

.clearSpider-Text h2 {
  font-family: monospace;
  font-size: 1.4vw;
  font-weight: 510;
  width: 34vw;
  line-height: 3vw;
}

.clear-Spider img {
  width: 50vw;
}

.cs-secondimg img {
  width: 75vw;
  padding-top: 2vw;
}

.angryGoose {
  padding: 10vw;
  gap: 0vw;
  background-color:#2d2727;
}

.goose-intro{
    columns:2;

}

.goose-intro img{
    width:38vw;
    padding-top:0vw;
    padding-bottom:1vw;
}

.goose-intro img:hover{
    transform: scale(1.1);
    transition: transform 0.3s ease;
    transform-origin: center;
    cursor:pointer;
}
.goose-intro .goose-text h1{
    font-family: monospace;
    flex: 1; /* Ensure both columns take up equal space */
    font-size: 3vw;
    padding-top: 3vw;
    width: 35vw;
    color: #f3f2f0;
}

.goose-intro .goose-text h2{
    font-family: monospace;
    font-size: 1.4vw;
    font-weight: 510;
    width: 34vw;
    line-height: 3vw;
    color: #f3f2f0;
}

.goose-pic h2{
  font-family: monospace;
  font-size: 1.4vw;
  font-weight: 510;
  width: 34vw;
  line-height: 30px;
  color: #f3f2f0;
  padding-left: 17vw;
}
.goose-process{
    columns:2;
    padding-top:9vw;
    gap:10vw;
}
.goose-process h2{
    font-family: monospace;
    font-size: 1.4vw;
    font-weight: 510;
    width: 33vw;
    line-height: 3vw;
    color: #f3f2f0;
    padding-top:10vw;

}

.goose-process img{
    width:35vw;
    border-radius:30px;

}

.goose-struggles{
    columns:2;
    padding-top:9vw;
    gap:0vw;
    padding-right:14vw;
}

.goose-struggles h2{
    font-family: monospace;
    font-size: 1.4vw;
    font-weight: 510;
    width: 25vw;
    line-height: 3vw;
    color: #f3f2f0;
    padding-top:10vw;
}

.goose-struggles img{
    width:45vw;
}

.goose-final{
    columns:2;
    padding-top:9vw;
    gap:0vw;
    padding-right:14vw;
}

.goose-final h2{
    font-family: monospace;
    font-size: 1.4vw;
    font-weight: 510;
    width: 30vw;
    line-height: 3vw;
    color: #f3f2f0;
    padding-top:7vw;
    padding-left: 15vw;
}

.goose-final img{
    border-radius:30px;
    width:40vw;
}

.scoobyMobile{
    margin-top: 10vw;
    margin-left: 10vw;
    margin-right: 30vw;
    columns:2;
    gap:4vw;
    margin-bottom:10vw;
}

.scooby-Text h1{
    font-family: monospace;
    flex: 1; /* Ensure both columns take up equal space */
    font-size: 3vw;
    padding-top: 5vw;
    width: 30vw;
}

.scooby-Text h2{
    font-family: monospace;
    font-size: 1.4vw;
    font-weight: 510;
    width:30vw;
    line-height: 3vw;
    padding-top:0vw;
}

.scoobyMobile img{
    width: 50vw;
}

@media (max-width: 700px) {
  .uwux-introText h2 {
    font-family: monospace;
    font-size: 1.4vw;
    font-weight: 510;
    width: 38vw;
    color: #f3f2f0;
    line-height: 3vw;
  }
  .uwux-intro img {
    width: 39vw;
    border-radius: 4vw;
    margin-top: 8vw;
    padding-left:1vw;
  }
  

}