.navhome {
  display: flex;
  margin-left: 80%;
  padding: 30px;
  /* background-color: #2d2727; */
  overflow-x: hidden;
  position: absolute;
}

.navhome .container {
  display: flex;
  max-width: 100vw; /* Ensure it doesn't exceed the viewport */
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%; /* Fill the entire width */
}

.navhome img {
  width: 100%;
}

.aboutmenav {
  display: flex;
  margin-left: 80%;
  padding: 30px;
  /* background-color: #2d2727; */
  overflow-x: hidden;
  position: absolute;
}

.navhome button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 5px;
  padding: 13px 10px;
  font-size: 1.25vw;
  color: #d1d0d0;
  font-family: monospace;
  position: relative;
}

.navhome button:hover {
  color: #b5d8ab;
}

.aboutmenav button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 5px;
  padding: 13px 10px;
  font-size: 1.25vw;
  color: #d1d0d0;
  font-family: monospace;
  position: relative;
}

.aboutmenav button:hover {
  color: #b5d8ab;
}

.projectsnav {
  display: flex;
  margin-left: 80%;
  padding: 30px;
  /* background-color: #2d2727; */
  overflow-x: hidden;
  position: absolute;
}

.projectsnav button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 5px;
  padding: 13px 10px;
  font-size: 1.25vw;
  color: #282828;
  font-family: monospace;
  position: relative;
}

.projectsnav button:hover {
  color: #6a6a6a;
}

@media (max-width: 700px) {
  .navhome {
    padding: 10px;
    position: absolute;
    margin-left: 60%;
    overflow-x: hidden;
  }

  .navhome button {
    font-size: 2.5vw;
    padding: 6px;
  }

  .aboutmenav {
    display: flex;
    margin-left: 60%;
    padding: 10px;
    /* background-color: #2d2727; */
    overflow-x: hidden;
    position: absolute;
  }
  .aboutmenav button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 5px;
    padding: 6px;
    font-size: 2.5vw;
    color: #d1d0d0;
    font-family: monospace;
  }

  .projectsnav {
    display: flex;
    margin-left: 60%;
    padding: 10px;
    /* background-color: #2d2727; */
    overflow-x: hidden;
    position: absolute;
  }

  .projectsnav button {
    color: #282828;
    border: none;
    cursor: pointer;
    margin: 5px;
    padding: 6px;
    font-size: 2.5vw;
    font-family: monospace;
    background-color: transparent;
  }
  }

