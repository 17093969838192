.hmHeader{
    columns:2;
    display:flex;
    padding-top:8vw;
    padding-left:8vw;
    padding-right:8vw;
    padding-bottom:4vw;
    background-color:rgb(246, 246, 246);
}

.hmHeader img{
    width:50vw;
}

.hmHeader h1{
    font-size:2.3vw;
    padding-top:12vw;
}


.hm-overview{
    font-size: 0.8vw;
    width: 65vw;
    padding-top:4vw;
  }

  .hm-overview h2 {
    font-weight: 500;
    margin-bottom: 3vw;
    line-height: 1.8vw;
  }

  .hm-overview h1{
    font-weight: 600;
  }

  .hm-problem{
    font-size: 0.8vw;
    width: 65vw;
    padding-top:1vw;
  }

  .hm-problem img{
    width: 65vw;
    padding-bottom: 4vw;
  }

  .hm-problem h2 {
    font-weight: 500;
    margin-bottom: 3vw;
    line-height: 1.8vw;
  }

  .hm-problem h1{
    font-weight: 600;
  }

  
  .hm-section3{
    font-size: 0.8vw;
    width: 65vw;
    padding-left: 18vw;
    padding-top:5vw;
    padding-bottom:5vw;
  }

  .hm-section3 img{
    width: 65vw;
    padding-bottom: 4vw;
  }

  .hm-section3 h2 {
    font-weight: 500;
    margin-bottom: 3vw;
    line-height: 1.8vw;
  }

  .hm-section3 h1{
    font-weight: 600;
  }

.userTesting{
    padding-top: 3vw;
}

.userTesting img{
    width: 60vw;
}



.final-text{
    background-color:#EFF8FF;
    padding-top:2vw;
    padding-left:18vw;
    padding-right:8vw;
    padding-bottom:2vw;
}
.final-text h2 {
    font-weight: 500;
    margin-bottom: 3vw;
    line-height: 1.8vw;
    font-size: 1.2vw;
  }

  .final-text h1{
    font-weight: 600;
    font-size: 1.8vw;
  }
.hm-section4{
    columns:2;
    display:flex;
}


.hm-section4 img{
    width: 40vw;
    padding: 5vw;
}

.hmsection4-text h2{
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.8vw;
}
.hmsection4-text h1{
    font-size:1.7vw;
    font-weight: 600;
}
.hmsection4-text{
    padding-left:12vw;
    padding-top:8vw;
    width:30vw;
}

.hm-section5{
    columns:2;
    display:flex;
    padding-left: 6vw;
}

.hm-section5 img{
    width: 40vw;
    padding: 5vw;
}

.hmsection5-text h2{
    font-weight: 500;
    font-size: 1.2vw;
    line-height: 1.8vw;
}
.hmsection5-text h1{
    font-size:1.7vw;
    font-weight: 600;
}
.hmsection5-text{
    padding-right:12vw;
    padding-top:8vw;
    width:30vw;
}

.hm-handoff img{
    width: 57vw;
    padding-bottom: 5vw;
  }


  .hm-handoff{
    font-size: 0.8vw;
    width: 65vw;
    padding-top:1vw;
  }

  .hm-handoff h2 {
    font-weight: 500;
    line-height: 1.8vw;
  }

  .hm-handoff h1{
    font-weight: 600;
    padding-bottom: 2vw;
  }




  .hm-lessons{
    font-size: 0.8vw;
    width: 65vw;
    padding-left: 18vw;
    padding-top:5vw;
    padding-bottom:5vw;
  }

  .hm-lessons h2 {
    font-weight: 500;
    line-height: 1.8vw;
  }

  .hm-lessons h1{
    font-weight: 600;
    padding-bottom: 2vw;
  }


  .hm-lessons h3{
    font-weight: 600;
    font-size: 1.2vw;
  }