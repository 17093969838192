.footerHome {
    background-color: #2d2727;
    
    text-align: left;
    position: relative; /* Change from fixed to relative */
    width: 100;
    display: flex;
    max-width: 100vw; /* Ensure it doesn't exceed the viewport */
    padding: 45 20px;
    padding-bottom:2vw;
  }
  
  .footer-container {
    display: flex;
    justify-content: left;
  }
  
  .footerHome button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    font-size: 1.1vw;
    background-color: transparent;
    color: rgb(255, 255, 255);
    font-family: monospace;
    font-weight: bold;
    cursor: pointer;
  }
  
  .footerHome h1 {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    font-size: 1.1vw;
    background-color: transparent;
    color: rgb(255, 255, 255);
    font-family: monospace;
    font-weight: bold;
  }
  
  .footerHome button:hover {
    color:#b5d8ab;
  }


  .footerAboutMe {
    background-color:#ffffff;
    overflow:hidden;
    text-align: left;
    position: relative; 
    bottom: 0;
    width: 97.23%;
    display: flex;
    max-width: 100vw; 
    padding: 45 20px;
    padding-bottom:2vw;
  }
  
  
  .footerAboutMe button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    font-size: 1.1vw;
    background-color: transparent;
    color:#2d2727 ;
    font-family: monospace;
    font-weight: bold;
    cursor: pointer;
  }
  
  .footerAboutMe h1 {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    font-size: 1.1vw;
    background-color: transparent;
    color:#2d2727 ;
    font-family: monospace;
    font-weight: bold;
  }
  
  .footerAboutMe button:hover {
    opacity:60%;
  }


  .footerSubBud {
    overflow:hidden;
    text-align: left;
    position: relative; 
    bottom: 0;
    width: 97.23%;
    display: flex;
    max-width: 100vw; 
    padding: 45 20px;
    padding-bottom:2vw;
  }
  
  
  .footerSubBud button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    font-size: 1.1vw;
    background-color: transparent;
    color:#2d2727 ;
    font-family: monospace;
    font-weight: bold;
    cursor: pointer;
  }
  
  .footerSubBud h1 {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    font-size: 1.1vw;
    background-color: transparent;
    color:#2d2727 ;
    font-family: monospace;
    font-weight: bold;
  }
  
  .footerSubBud button:hover {
    opacity:60%;
  }

  @media (max-width: 480px) {
    .footerHome button {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      font-size: 2vw;
      background-color: transparent;
      color: rgb(255, 255, 255);
      font-family: monospace;
      font-weight: bold;
      cursor: pointer;
    }
    
    .footerHome h1 {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      font-size: 2vw;
      background-color: transparent;
      color: rgb(255, 255, 255);
      font-family: monospace;
      font-weight: bold;
    }
    
    .footerHome button:hover {
      color:#b5d8ab;
    }
  
  
    .footerAboutMe {
      background-color:#ffffff;
      overflow:hidden;
      text-align: left;
      position: relative; 
      width: 97.23%;
      display: flex;
      max-width: 100vw; 
      padding-top: 8vw;
      padding-bottom:3vw;
    }
    
    
    .footerAboutMe button {
      margin: 0 2vw;
      padding: 2vw 4vw;
      border: none;
      font-size: 2vw;
      background-color: transparent;
      color:#2d2727 ;
      font-family: monospace;
      font-weight: bold;
      cursor: pointer;
    }
    
    .footerAboutMe h1 {
      margin: 0 2vw;
      padding: 2vw 4vw;
      border: none;
      font-size: 2vw;
      background-color: transparent;
      color:#2d2727 ;
      font-family: monospace;
      font-weight: bold;
    }
    
    .footerAboutMe button:hover {
      opacity:60%;
    }
  
  
    .footerSubBud {
      overflow:hidden;
      padding: 20px;
      text-align: left;
      position: relative; 
      bottom: 0;
      width: 97.23%;
      display: flex;
      max-width: 100vw; 
      padding: 45 20px;
      padding-bottom:2vw;
    }
    
    
    .footerSubBud button {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      font-size: 2vw;
      background-color: transparent;
      color:#2d2727 ;
      font-family: monospace;
      font-weight: bold;
      cursor: pointer;
    }
    
    .footerSubBud h1 {
      margin: 0 10px;
      padding: 10px 20px;
      border: none;
      font-size: 2vw;
      background-color: transparent;
      color:#2d2727 ;
      font-family: monospace;
      font-weight: bold;
    }
  }